var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"site grid-container container hfeed",staticStyle:{"height":"auto !important"},attrs:{"id":"app"}},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"site-content",staticStyle:{"height":"auto !important"},attrs:{"id":"content"}},[_c('article',{staticClass:"center",staticStyle:{"height":"auto !important"},attrs:{"id":"article"}},[_c('section',{staticStyle:{"height":"auto !important"},attrs:{"id":"downloadpage"}},[_c('div',{staticClass:"download-card"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"wp-block-buttons aligncenter",staticStyle:{"width":"100%","display":"block","text-align":"center"},attrs:{"id":"download"},on:{"click":function($event){return _vm.download()}}},[_c('div',{staticClass:"wp-block-button full-button"},[_c('div',{staticClass:"godxthemes-btn wp-block-button__linkvk ripple wp-block-button__link",staticStyle:{"width":"100%","margin-bottom":"0"}},[_c('i',{staticClass:"material-icons"},[_c('svg',{attrs:{"version":"1.1","viewBox":"0 0 35 35","xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","fill":"#fff"}},[_c('path',{attrs:{"d":"m16 16-3.25-3.25-0.75 0.75 4.5 4.5 4.5-4.5-0.75-0.75-3.25 3.25v-11h-1v11zm5 2h6.775l-4.375-7h-5.4v-1h6l5 8v1 8h-25v-9l5-8h6v1h-5.4l-4.375 7h6.775v2c0 1.1046 0.89586 2 1.9974 2h5.0052c1.1031 0 1.9974-0.88773 1.9974-2v-2z"}})])]),_vm._v("\n                GB WhatsApp Download\n              ")])])]),_vm._v(" "),_vm._m(2)]),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_c('div',{staticClass:"wp-block-buttons aligncenter",staticStyle:{"width":"100%","display":"block","text-align":"center"},attrs:{"id":"download"},on:{"click":function($event){return _vm.download()}}},[_vm._m(5)]),_vm._v(" "),_vm._m(6)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"has-branding main-navigation grid-container nav-align-right sub-menu-right",attrs:{"id":"site-navigation","aria-label":"Primary","itemtype":"https://schema.org/SiteNavigationElement","itemscope":""}},[_c('div',{staticClass:"inside-navigation grid-container"},[_c('div',{staticClass:"navigation-branding"},[_c('div',{staticClass:"site-logo"},[_c('a',{attrs:{"href":"https://gbapks.com.ru/","title":"GBWhatsApp","rel":"home"}},[_c('img',{staticClass:"header-image is-logo-image",attrs:{"alt":"GBWhatsApp","src":require("../assets/logo.webp"),"title":"GBWhatsApp"}})])]),_vm._v(" "),_c('p',{staticClass:"main-title",attrs:{"itemprop":"headline"}},[_c('a',{attrs:{"href":"https://gbapks.com.ru/","rel":"home"}},[_vm._v("\n            GBWhatsApp\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"main-nav",attrs:{"id":"primary-menu"}},[_c('ul',{staticClass:"menu sf-menu",attrs:{"id":"menu-primary-menu"}},[_c('li',{staticClass:"menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32",attrs:{"id":"menu-item-32"}},[_c('a',{attrs:{"href":"https://gbapks.com.ru/","aria-current":"page"}},[_vm._v("Home")])]),_vm._v(" "),_c('li',{staticClass:"menu-item menu-item-type-post_type menu-item-object-post menu-item-33",attrs:{"id":"menu-item-33"}},[_c('a',{attrs:{"href":"https://gbapks.com.ru/gbwhatsapp-downloadpage/"}},[_vm._v("Download")])]),_vm._v(" "),_c('li',{staticClass:"menu-item menu-item-type-post_type menu-item-object-post menu-item-33",attrs:{"id":"menu-item-33"}},[_c('a',{attrs:{"href":"https://gbapks.com.ru/blogs/"}},[_vm._v("Blogs")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download-header"},[_c('div',{staticClass:"site-logo"},[_c('img',{staticClass:"header-image is-logo-image",attrs:{"alt":"GBWhatsApp","src":require("../assets/logo.webp"),"title":"GBWhatsApp"}})]),_vm._v(" "),_c('h1',[_vm._v("GBWhatsApp Latest Version Download For Android")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('small',[_vm._v("By installing WhatsApp, you agree to our "),_c('a',{staticClass:"jump-url",attrs:{"href":"https://gbapks.com.ru/privacy/"}},[_vm._v("Privacy Policy")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download-card"},[_c('h2',[_vm._v("GBWhatsApp App Info")]),_vm._v(" "),_c('figure',[_c('table',[_c('tr',[_c('td',[_vm._v("App Name")]),_vm._v(" "),_c('td',[_vm._v("GB WhatsApp")])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v("Size")]),_vm._v(" "),_c('td',[_vm._v("77MB")])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v("Version")]),_vm._v(" "),_c('td',[_vm._v("v18.30")])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v("Downloads")]),_vm._v(" "),_c('td',[_vm._v("2M+")])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v("License")]),_vm._v(" "),_c('td',[_vm._v("Free")])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v("Category")]),_vm._v(" "),_c('td',[_vm._v("Mod Apk")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download-card"},[_c('h2',[_vm._v("How to Download and Install GB WhatsApp for Android")]),_vm._v(" "),_c('ul',[_c('li',[_c('strong',[_vm._v("1. Click the \"GB WhatsApp Download\" Button: ")]),_vm._v("Tap the button at the top of this page to begin the download.")]),_vm._v(" "),_c('li',[_c('strong',[_vm._v("2. Enable \"Install Unknown Sources\": ")]),_vm._v("Go to your phone's settings, and enable this option to allow installation from external sources.")]),_vm._v(" "),_c('li',[_c('strong',[_vm._v("3. Install the App: ")]),_vm._v("Open the downloaded file, follow the installation prompts.")]),_vm._v(" "),_c('li',[_c('strong',[_vm._v("4. Verify Your Phone Number: ")]),_vm._v("Enter your phone number and complete the verification process to start using GB WhatsApp.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wp-block-button full-button"},[_c('a',{staticClass:"godxthemes-btn wp-block-button__linkvk ripple wp-block-button__link",staticStyle:{"width":"100%","margin-bottom":"0"},attrs:{"href":"https://gbapks.com.ru/"}},[_vm._v("\n              Back to Home\n            ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"site-footer grid-container footer-bar-active footer-bar-align-right"},[_c('footer',{staticClass:"site-info"},[_c('div',{staticClass:"inside-site-info grid-container"},[_c('div',{staticClass:"footer-bar"},[_c('a',{attrs:{"href":"https://gbapks.com.ru/privacy/"}},[_vm._v("Privacy Policy")]),_vm._v("\n                |\n                "),_c('a',{attrs:{"href":"https://gbapks.com.ru/about-us/"}},[_vm._v("About Us")])]),_vm._v(" "),_c('div',{staticClass:"copyright-bar"},[_vm._v("\n                2025 © All Rights Reserved "),_c('strong',[_c('a',{attrs:{"href":"https://gbapks.com.ru/"}},[_vm._v("GBWhatsApp")])])])])])])
}]

export { render, staticRenderFns }